<template>
    <div class="container-form">
        <template v-if="loaded">
            <div class="form-tbf">
                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.personal-email') }}</label>
                    </div>
                    <div class="input-box" v-bind:class="{has_error: error_business_email}">
                        <div class="icon-left"><icon-email /></div>
                        <div class="icon-right" v-if="business_email != ''" @click.stop="business_email = ''"><icon-close class="icon-clear" /></div>
                        <input type="email" :placeholder="$t('create-user.email-ph')" class="input-text" v-model="business_email" @change="error_business_email = false">
                    </div>
                </div>
                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.date-of-birth') }}</label>
                        <div v-if="errorsBe.date_of_birth" class="error-msg">{{ errorsBe.date_of_birth.join(" | ") }}</div>
                    </div>
                    <div class="input-box">
                        <div class="icon-left"><icon-calendar /></div>
                        <div class="icon-right" v-if="Object.keys(date_of_birth).length && date_of_birth.selectedDate" @click.stop="date_of_birth = {}"><icon-close class="icon-clear" /></div>
                        <FunctionalCalendar
                            ref="CalendarDateOfBirth"
                            v-model="date_of_birth"
                            class="calendar-tbf"
                            :configs="calendarConfigs"
                        >
                            <template v-slot:datePickerInput="props">
                                <input
                                class="vfc-single-input custom-input-picker"
                                type="text"
                                :value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
                                v-if="Object.keys(date_of_birth).length"/>

                                <div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('create-user.choose-date-of-birth') }}</div>
                            </template>
                        </FunctionalCalendar>
                    </div>
                </div>
                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && !$mq.above(600) ? 'w-100' : 'w-50'">
                    <div class="label-header">
                        <label class="label">{{$t('show_user.timezone')}}</label>
                    </div>
                    <div class="input-box">
                        <div class="icon-left"><icon-globe /></div>
                        <multiselect 
                        v-model="selectedTimezone"
                        :options="optionsTimezones"
                        :allow-empty="false"
                        :show-labels="false"
                        :multiple="false" 
                        :close-on-select="true"
                        track-by="value" 
                        label="name"
                        >
                            <template slot="placeholder" slot-scope="props">
                                <span class="text">{{$t('show_user.timezone')}}</span>
                            </template>
                            <template slot="noResult">{{ $t('general.no_result') }}</template>
                            <template slot="noOptions">{{ $t('general.empty_list') }}</template>
                        </multiselect>
                    </div>
                </div>
                <div class="input-group w-50">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.married') }}</label>
                    </div>
                    <div class="input-box bor-0">
                        <div class="checkbox-list">
                            <div class="checkbox-item m-4" @click="married = 0" v-bind:class="{active: married === 0}">
                                <div class="name">{{ $t('create-user.no') }}</div>
                            </div>
                            <div class="checkbox-item m-4" @click="married = 1" v-bind:class="{active: married === 1}">
                                <div class="name">{{ $t('create-user.yes') }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="input-group w-50">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.childrens') }}</label>
                    </div>
                    <div class="input-box bor-0">
                        <div class="checkbox-list">
                            <div class="checkbox-item m-4" @click="has_children = 0" v-bind:class="{active: has_children === 0}">
                                <div class="name">{{ $t('create-user.no') }}</div>
                            </div>
                            <div class="checkbox-item m-4" @click="has_children = 1" v-bind:class="{active: has_children === 1}">
                                <div class="name">{{ $t('create-user.yes') }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && !$mq.above(600) ? 'w-100' : 'w-50'">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.blood-type') }}</label>
                    </div>
                    <div class="input-box">
                        <div class="icon-left"><icon-blood /></div>
                        <multiselect 
                        v-model="selectedBloodType"
                        :options="optionsBloodTypes"
                        :allow-empty="false"
                        :show-labels="false"
                        :multiple="false" 
                        :close-on-select="true"
                        >
                            <template slot="placeholder" slot-scope="props">
                                <span class="text">{{$t('create-user.blood-type-ph')}}</span>
                            </template>
                            <template slot="singleLabel" slot-scope="props">{{ $t(`create-user.blood-type-options.${props.option}`) }}</template>
                            <template slot="option" slot-scope="props">{{ $t(`create-user.blood-type-options.${props.option}`) }}</template>
                            <template slot="noResult">{{ $t('general.no_result') }}</template>
                            <template slot="noOptions">{{ $t('general.empty_list') }}</template>
                        </multiselect>
                    </div>
                </div>

                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.emergency_phone') }}</label>
                        <div v-if="errorsBe.emergency_phone" class="error-msg">{{ errorsBe.emergency_phone.join(" | ") }}</div>
                    </div>
                    <div class="input-box">
                        <div class="icon-left"><icon-mobile /></div>
                        <div class="icon-right" v-if="emergency_phone != ''" @click.stop="emergency_phone = ''"><icon-close class="icon-clear" /></div>
                        <input type="text" :placeholder="$t('create-user.phone-ph')" class="input-text" v-model="emergency_phone">
                    </div>
                </div>

                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.address') }}</label>
                    </div>
                    <div class="input-box">
                        <div class="icon-left"><icon-building /></div>
                        <div class="icon-right" v-if="address != ''" @click.stop="address = ''"><icon-close class="icon-clear" /></div>
                        <input type="text" :placeholder="$t('create-user.address-ph')" class="input-text" v-model="address">
                    </div>
                </div>

                <div class="delimeter-form"></div>

                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.facebook_link') }}</label>
                    </div>
                    <div class="input-box">
                        <div class="icon-left"><icon-facebook /></div>
                        <div class="icon-right" v-if="social_links.facebook_link != ''" @click.stop="social_links.facebook_link = ''"><icon-close class="icon-clear" /></div>
                        <input type="text" :placeholder="$t('create-user.facebook_link-ph')" class="input-text" v-model="social_links.facebook_link">
                    </div>
                </div>
                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.linkedin_link') }}</label>
                    </div>
                    <div class="input-box">
                        <div class="icon-left"><icon-linkedin /></div>
                        <div class="icon-right" v-if="social_links.linkedin_link != ''" @click.stop="social_links.linkedin_link = ''"><icon-close class="icon-clear" /></div>
                        <input type="text" :placeholder="$t('create-user.linkedin_link-ph')" class="input-text" v-model="social_links.linkedin_link">
                    </div>
                </div>
                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.instagram_link') }}</label>
                    </div>
                    <div class="input-box">
                        <div class="icon-left"><icon-instagram /></div>
                        <div class="icon-right" v-if="social_links.instagram_link != ''" @click.stop="social_links.instagram_link = ''"><icon-close class="icon-clear" /></div>
                        <input type="text" :placeholder="$t('create-user.instagram_link-ph')" class="input-text" v-model="social_links.instagram_link">
                    </div>
                </div>
                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.twitter_link') }}</label>
                    </div>
                    <div class="input-box">
                        <div class="icon-left"><icon-twitter /></div>
                        <div class="icon-right" v-if="social_links.twitter_link != ''" @click.stop="social_links.twitter_link = ''"><icon-close class="icon-clear" /></div>
                        <input type="text" :placeholder="$t('create-user.twitter_link-ph')" class="input-text" v-model="social_links.twitter_link">
                    </div>
                </div>
                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.tiktok_link') }}</label>
                    </div>
                    <div class="input-box">
                        <div class="icon-left"><icon-tiktok /></div>
                        <div class="icon-right" v-if="social_links.tiktok_link != ''" @click.stop="social_links.tiktok_link = ''"><icon-close class="icon-clear" /></div>
                        <input type="text" :placeholder="$t('create-user.tiktok_link-ph')" class="input-text" v-model="social_links.tiktok_link">
                    </div>
                </div>
                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.youtube_link') }}</label>
                    </div>
                    <div class="input-box">
                        <div class="icon-left"><icon-youtube /></div>
                        <div class="icon-right" v-if="social_links.youtube_link != ''" @click.stop="social_links.youtube_link = ''"><icon-close class="icon-clear" /></div>
                        <input type="text" :placeholder="$t('create-user.youtube_link-ph')" class="input-text" v-model="social_links.youtube_link">
                    </div>
                </div>

                <div class="delimeter-form"></div>

                <div class="saved-languages" v-if="savedLanguages.length">
                    <div class="box">
                        <div class="group-language" v-for="languageItem in savedLanguages">
                            <div class="main-label">
                                <div class="name">{{ languageItem.name }}</div>
                                <div class="actions">
                                    <button class="btn-tbf white" @click="editLanguageLevel(languageItem)"><icon-edit /></button>
                                    <button class="btn-tbf white" @click="deleteLanguageLevel(languageItem)"><icon-trash /></button>
                                </div>
                            </div>
                            <div class="levels-values">
                                <div class="item-level">
                                    <div class="label">{{ $t('create-user.listening') }}</div>
                                    <div class="value">{{ languageItem.levels.find(el => el.type == 'listening').level == 'undefined' ? $t('evaluation.not_completed') : languageItem.levels.find(el => el.type == 'listening').level }}</div>
                                </div>
                                <div class="item-level">
                                    <div class="label">{{ $t('create-user.reading') }}</div>
                                    <div class="value">{{ languageItem.levels.find(el => el.type == 'reading').level == 'undefined' ? $t('evaluation.not_completed') : languageItem.levels.find(el => el.type == 'reading').level }}</div>
                                </div>
                                <div class="item-level">
                                    <div class="label">{{ $t('create-user.spoke-interaction') }}</div>
                                    <div class="value">{{ languageItem.levels.find(el => el.type == 'spoke_interaction').level == 'undefined' ? $t('evaluation.not_completed') : languageItem.levels.find(el => el.type == 'spoke_interaction').level }}</div>
                                </div>
                                <div class="item-level">
                                    <div class="label">{{ $t('create-user.spoke-production') }}</div>
                                    <div class="value">{{ languageItem.levels.find(el => el.type == 'spoke_production').level == 'undefined' ? $t('evaluation.not_completed') : languageItem.levels.find(el => el.type == 'spoke_production').level }}</div>
                                </div>
                                <div class="item-level">
                                    <div class="label">{{ $t('create-user.writing') }}</div>
                                    <div class="value">{{ languageItem.levels.find(el => el.type == 'writing').level == 'undefined' ? $t('evaluation.not_completed') : languageItem.levels.find(el => el.type == 'writing').level }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && $mq.below(600) ? 'w-100' : 'w-50'">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.languages') }}</label>
                    </div>
                    <div class="input-box">
                        <div class="icon-left"><icon-globe /></div>
                        <div class="icon-right" v-if="selectedLanguage != ''" @click.stop="selectedLanguage = ''"><icon-close class="icon-clear" /></div>
                        <multiselect 
                            v-model="selectedLanguage"
                            class="select-tags-tbf"
                            v-bind:class="{populate: selectedLanguage != ''}"
                            :options="optionsLanguages"
                            :allow-empty="true"
                            :show-labels="false"
                            track-by="id" 
                            label="name"
                            >
                            <template slot="placeholder" slot-scope="props">
                                <span class="text">
                                    {{ $t('create-user.choose-language') }}
                                </span>
                            </template>
                            <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                        </multiselect>
                    </div>
                </div>

                <div class="levels-language" v-if="selectedLanguage">
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && $mq.below(600) ? 'w-100' : 'w-50'">
                        <div class="label-header">
                            <label class="label">{{ $t('create-user.listening') }}</label>
                            
                        </div>
                        <div class="input-box">
                            <div class="icon-left"><icon-level /></div>
                            <div class="icon-right" v-if="selectedLevelListening != ''" @click.stop="selectedLevelListening = ''"><icon-close class="icon-clear" /></div>
                            <multiselect 
                                v-model="selectedLevelListening"
                                v-bind:class="{populate: selectedLevelListening != ''}"
                                class="select-tags-tbf"
                                :options="optionsListening"
                                :allow-empty="false"
                                :show-labels="false"
                                track-by="value" 
                                label="text"
                                >
                                <template slot="placeholder" slot-scope="props">
                                    <span class="text">
                                        {{ $t('create-user.choose-level') }}
                                    </span>
                                </template>
                                <template slot="singleLabel" slot-scope="props">
                                    <div class="option_ellipsis">{{ props.option.value }}</div>
                                </template>
                                <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                            </multiselect>
                        </div>
                    </div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && $mq.below(600) ? 'w-100' : 'w-50'">
                        <div class="label-header">
                            <label class="label">{{ $t('create-user.reading') }}</label>
                            
                        </div>
                        <div class="input-box">
                            <div class="icon-left"><icon-level /></div>
                            <div class="icon-right" v-if="selectedLevelReading != ''" @click.stop="selectedLevelReading = ''"><icon-close class="icon-clear" /></div>
                            <multiselect 
                                v-model="selectedLevelReading"
                                v-bind:class="{populate: selectedLevelReading != ''}"
                                class="select-tags-tbf"
                                :options="optionsReading"
                                :allow-empty="false"
                                :show-labels="false"
                                track-by="value" 
                                label="text"
                                >
                                <template slot="placeholder" slot-scope="props">
                                    <span class="text">
                                        {{ $t('create-user.choose-level') }}
                                    </span>
                                </template>
                                <template slot="singleLabel" slot-scope="props">
                                    <div class="option_ellipsis">{{ props.option.value }}</div>
                                </template>
                                <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                            </multiselect>
                        </div>
                    </div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && $mq.below(600) ? 'w-100' : 'w-50'">
                        <div class="label-header">
                            <label class="label">{{ $t('create-user.spoke-interaction') }}</label>
                            
                        </div>
                        <div class="input-box">
                            <div class="icon-left"><icon-level /></div>
                            <div class="icon-right" v-if="selectedLevelSpInt != ''" @click.stop="selectedLevelSpInt = ''"><icon-close class="icon-clear" /></div>
                            <multiselect 
                                v-model="selectedLevelSpInt"
                                class="select-tags-tbf"
                                v-bind:class="{populate: selectedLevelSpInt != ''}"
                                :options="optionsSpokenInteraction"
                                :allow-empty="false"
                                :show-labels="false"
                                track-by="value" 
                                label="text"
                                >
                                <template slot="placeholder" slot-scope="props">
                                    <span class="text">
                                        {{ $t('create-user.choose-level') }}
                                    </span>
                                </template>
                                <template slot="singleLabel" slot-scope="props">
                                    <div class="option_ellipsis">{{ props.option.value }}</div>
                                </template>
                                <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                            </multiselect>
                        </div>
                    </div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && $mq.below(600) ? 'w-100' : 'w-50'">
                        <div class="label-header">
                            <label class="label">{{ $t('create-user.spoke-production') }}</label>
                            
                        </div>
                        <div class="input-box">
                            <div class="icon-left"><icon-level /></div>
                            <div class="icon-right" v-if="selectedLevelSpPro != ''" @click.stop="selectedLevelSpPro = ''"><icon-close class="icon-clear" /></div>
                            <multiselect 
                                v-model="selectedLevelSpPro"
                                class="select-tags-tbf"
                                v-bind:class="{populate: selectedLevelSpPro != ''}"
                                :options="optionsSpokenProduction"
                                :allow-empty="false"
                                :show-labels="false"
                                track-by="value" 
                                label="text"
                                >
                                <template slot="placeholder" slot-scope="props">
                                    <span class="text">
                                        {{ $t('create-user.choose-level') }}
                                    </span>
                                </template>
                                <template slot="singleLabel" slot-scope="props">
                                    <div class="option_ellipsis">{{ props.option.value }}</div>
                                </template>
                                <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                            </multiselect>
                        </div>
                    </div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && $mq.below(600) ? 'w-100' : 'w-50'">
                        <div class="label-header">
                            <label class="label">{{ $t('create-user.writing') }}</label>
                        </div>
                        <div class="input-box">
                            <div class="icon-left"><icon-level /></div>
                            <div class="icon-right" v-if="selectedLevelWriting != ''" @click.stop="selectedLevelWriting = ''"><icon-close class="icon-clear" /></div>
                            <multiselect 
                                v-model="selectedLevelWriting"
                                class="select-tags-tbf"
                                v-bind:class="{populate: selectedLevelWriting != ''}"
                                :options="optionsWriting"
                                :allow-empty="false"
                                :show-labels="false"
                                track-by="value" 
                                label="text"
                                >
                                <template slot="placeholder" slot-scope="props">
                                    <span class="text">
                                        {{ $t('create-user.choose-level') }}
                                    </span>
                                </template>
                                <template slot="singleLabel" slot-scope="props">
                                    <div class="option_ellipsis">{{ props.option.value }}</div>
                                </template>
                                <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                                <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                            </multiselect>
                        </div>
                    </div>
                    <div class="input-group input-group-btn" :class="$resize && $mq.between([1071,1150]) || $resize && $mq.below(600) ? 'w-100' : 'w-50'">
                        <div class="add-more-btn" :class="[{'not-padd': $resize && $mq.between([1071,1150]) || $resize && $mq.below(600)}]">
                            <button class="btn-tbf blue" @click="addOptionLanguage">
                                <span class="text">{{ $t('create-user.add-another-language') }}</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="delimeter-form"></div>

                <div class="saved-skills" v-if="savedSkills.length">
                    <div class="box">
                        <div class="group-skill" v-for="skillItem in savedSkills">
                            <div class="main-label">
                                <div class="name">{{ skillItem.name }}</div>
                                <div class="actions">
                                    <button class="btn-tbf white" @click="editSkillLevel(skillItem)"><icon-edit /></button>
                                    <button class="btn-tbf white" @click="deleteSkillLevel(skillItem)"><icon-trash /></button>
                                </div>
                            </div>
                            <div class="level-skill">
                                {{ skillItem.level ? $t(`skill.type.${skillItem.level}`) : $t('evaluation.not_completed') }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && $mq.below(600) ? 'w-100' : 'w-50'">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.skill') }}</label>
                        <div class="create-new" @click="showModal('skill', {from: 'create-user'})">{{$t('create-user.add-skill')}}</div>
                    </div>
                    <div class="input-box">
                        <div class="icon-left"><icon-skill /></div>
                        <div class="icon-right" v-if="selectedSkill != ''" @click.stop="selectedSkill = ''"><icon-close class="icon-clear" /></div>
                        <multiselect 
                            v-model="selectedSkill"
                            v-bind:class="{populate: selectedSkill != ''}"
                            class="select-tags-tbf"
                            :options="optionsSkills"
                            :allow-empty="true"
                            :show-labels="false"
                            track-by="id" 
                            label="name"
                            >
                            <template slot="placeholder" slot-scope="props">
                                <span class="text">
                                    {{ $t('create-user.choose-skill') }}
                                </span>
                            </template>
                            <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                        </multiselect>
                    </div>
                </div>

                <div class="input-group" v-if="selectedSkill" :class="{'w-25': $resize && $mq.above(1330), 'w-50': $resize && $mq.between([600,1329]), 'w-100': $resize && $mq.below(600)}">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.level') }}</label>
                        <div v-if="$v.selectedSkillLevel.$error" class="error-msg">{{ $t('validator.required') }}</div>
                    </div>
                    <div class="input-box" v-bind:class="{has_error: $v.selectedSkillLevel.$error}">
                        <div class="icon-left"><icon-level /></div>
                        <div class="icon-right" v-if="selectedSkillLevel != ''" @click.stop="selectedSkillLevel = ''"><icon-close class="icon-clear" /></div>
                        <multiselect 
                            v-model="selectedSkillLevel"
                            v-bind:class="{populate: selectedSkillLevel != ''}"
                            class="select-tags-tbf"
                            :options="optionsSkillsLevels"
                            :allow-empty="false"
                            :show-labels="false"
                            track-by="key" 
                            label="name"
                            >
                            <template slot="placeholder" slot-scope="props">
                                <span class="text">
                                    {{ $t('create-user.choose-level') }}
                                </span>
                            </template>
                            <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                        </multiselect>
                    </div>
                </div>

                <div class="input-group input-group-btn" :class="{'w-25': $resize && $mq.above(1330), 'w-50': $resize && $mq.between([600,1329]), 'w-100': $resize && $mq.below(600)}" v-if="selectedSkill">
                    <div class="add-more-btn" :class="[{'not-padd': $resize && $mq.below(1070) || $mq.between([1151,1329])}]">
                        <button class="btn-tbf blue" @click="addSkill">
                            <span class="text">{{ $t('create-user.add-another-skill') }}</span>
                        </button>
                    </div>
                </div>

                <div class="delimeter-form"></div>

                <div class="input-group w-100">
                    <div class="add-task">
                        <div class="main-input">
                            <multiselect class="multiselect-hobbies" 
                            v-model="inputHobby" 
                            :placeholder="$t('create-user.placeholder_hobby')" 
                            :options="optionsHobbies" 
                            :taggable="true"
                            :tag-placeholder="$t('v-multiselect.add_new_hobby')" 
                            :selectLabel="$t('v-multiselect.select')"
                            :selectedLabel="$t('v-multiselect.selected')"
                            :deselectLabel="$t('v-multiselect.deselect')"
                            @tag="addNewHobby">
                            <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                            </multiselect>
                        </div>
                        <div class="actions" v-if="inputHobby != ''">
                            <button class="btn-tbf white" @click="inputHobby = ''"><span class="text">{{ $t('general.cancel') }}</span></button>
                            <button class="btn-tbf blue" @click="addHobby"><span class="text">{{ $t('general.add') }}</span></button>
                        </div>
                    </div>
                </div>

                <div v-if="savedHobbies.length > 0" class="input-group w-100">
                    <div class="textarea-selected-hobbies">
                        <div v-for="hobby, index in savedHobbies" class="file-item">
                            <span class="name">
                                {{ hobby }}
                                <button class="remove" @click="deleteHobby(index)"><icon-close /></button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-submit with-steps">
                <div class="error-msg" v-show="error_message != ''">{{ error_message }}</div>
                <div class="success-msg" v-show="success_message != ''">{{ success_message }}</div>

                <div class="actions-steps">
                    <button class="btn-tbf white only-icon prev" @click="changeStepFunction('prevStep')">
                        <div class="icon"><icon-arrow /></div>
                    </button>
                    <button class="btn-tbf white only-icon" @click="changeStepFunction('nextStep')">
                        <div class="icon"><icon-arrow /></div>
                    </button>
                </div>

                <div class="action-form">
                    <button id="buttonSubmit" class="btn-tbf blue" @click="saveAction()">
                        <div class="loader-spin" v-if="loadedSubmit"></div>
                        <span class="text" v-else>{{ $t('general.update') }}</span>
                    </button>
                </div>
            </div>
        </template>
        <LoaderStepPersonalDetails v-else />
    </div>
</template>

<script>
import IconEmail from '@/components/Icons/Email'
import IconClose from '@/components/Icons/Close'
import IconCalendar from '@/components/Icons/Calendar'
import IconGlobe from '@/components/Icons/Globe'
import IconBlood from '@/components/Icons/Blood'
import IconFacebook from '@/components/Icons/Facebook'
import IconLinkedin from '@/components/Icons/Linkedin'
import IconInstagram from '@/components/Icons/Instagram'
import IconTwitter from '@/components/Icons/Twitter'
import IconTiktok from '@/components/Icons/Tiktok'
import IconYoutube from '@/components/Icons/Youtube'
import IconArrow from '@/components/Icons/AngleRight'
import IconSkill from '@/components/Icons/Skill'
import IconLevel from '@/components/Icons/Level'
import IconEdit from '@/components/Icons/Edit'
import IconTrash from '@/components/Icons/Trash'
import LoaderStepPersonalDetails from '@/components/PagesLoaders/CreateUserStepPersonalDetails'
import IconMobile from '@/components/Icons/Mobile'
import IconBuilding from '@/components/Icons/Building'

import { required, requiredIf } from 'vuelidate/lib/validators'

export default {
    components: {
        IconClose,
        IconEmail,
        IconMobile,
        IconBuilding,
        IconCalendar,
        IconGlobe,
        IconBlood,
        IconFacebook,
        IconLinkedin,
        IconInstagram,
        IconTwitter,
        IconTiktok,
        IconYoutube,
        IconArrow,
        IconSkill,
        IconLevel,
        IconEdit,
        IconTrash,
        LoaderStepPersonalDetails
    },
    props: {
        user: Object
    },
    data() {
        return {
            loaded: false,
            loadedSubmit: false,
            business_email: '',
            date_of_birth: {},
            selectedTimezone: '',
            optionsTimezones: [],
            married: 0,
            has_children: 0,
            emergency_phone: '',
            address: '',
            selectedBloodType: '',
            optionsBloodTypes: ["A+", "A-", "B+", "B-", "AB+", "AB-", "0+", "0-"],
            social_links: {
                facebook_link: '',
                linkedin_link: '',
                instagram_link: '',
                twitter_link: '',
                tiktok_link: '',
                youtube_link: ''
            },
            calendarConfigs: {
                dateFormat: 'yyyy-mm-dd',
                isDatePicker: true,
                isDateRange: false,
                isModal: true,
                isAutoCloseable: true,
                changeMonthFunction: true,
                changeYearFunction: true
            },

            // Languages
            optionsLanguages: [],
            selectedLanguage: '',
            savedLanguages: [],
            selectedLevelListening: '',
            selectedLevelReading: '',
            selectedLevelSpInt: '',
            selectedLevelSpPro: '',
            selectedLevelWriting: '',
            optionsListening: [
                {value: 'A1', text: this.$t('create-user.listening-a1')},
                {value: 'A2', text: this.$t('create-user.listening-a2')},
                {value: 'B1', text: this.$t('create-user.listening-b1')},
                {value: 'B2', text: this.$t('create-user.listening-b2')},
                {value: 'C1', text: this.$t('create-user.listening-c1')},
                {value: 'C2', text: this.$t('create-user.listening-c2')}
            ],
            optionsReading: [
                {value: 'A1', text: this.$t('create-user.reading-a1')},
                {value: 'A2',text: this.$t('create-user.reading-a2')},
                {value: 'B1',text: this.$t('create-user.reading-b1')},
                {value: 'B2',text: this.$t('create-user.reading-b2')},
                {value: 'C1',text: this.$t('create-user.reading-c1')},
                {value: 'C2',text: this.$t('create-user.reading-c2')}
            ],
            optionsSpokenInteraction: [
                {value: 'A1',text: this.$t('create-user.spoke-interaction-a1')},
                {value: 'A2',text: this.$t('create-user.spoke-interaction-a2')},
                {value: 'B1',text: this.$t('create-user.spoke-interaction-b1')},
                {value: 'B2',text: this.$t('create-user.spoke-interaction-b2')},
                {value: 'C1',text: this.$t('create-user.spoke-interaction-c1')},
                {value: 'C2',text: this.$t('create-user.spoke-interaction-c2')}
            ],
            optionsSpokenProduction: [
                {value: 'A1',text: this.$t('create-user.spoke-interaction-a1')},
                {value: 'A2',text: this.$t('create-user.spoke-interaction-a2')},
                {value: 'B1',text: this.$t('create-user.spoke-interaction-b1')},
                {value: 'B2',text: this.$t('create-user.spoke-interaction-b2')},
                {value: 'C1',text: this.$t('create-user.spoke-interaction-c1')},
                {value: 'C2',text: this.$t('create-user.spoke-interaction-c2')}
            ],
            optionsWriting: [
                {value: 'A1',text: this.$t('create-user.writing-a1')},
                {value: 'A2',text: this.$t('create-user.writing-a2')},
                {value: 'B1',text: this.$t('create-user.writing-b1')},
                {value: 'B2',text: this.$t('create-user.writing-b2')},
                {value: 'C1',text: this.$t('create-user.writing-c1')},
                {value: 'C2',text: this.$t('create-user.writing-c2')}
            ],
            // Skills
            optionsSkills: [],
            selectedSkill: '',
            savedSkills: [],
            optionsSkillsLevels: [{name: 'Professional' , key: 'professional'},{name: 'Medium' , key: 'medium'},{name: 'Beginner' , key: 'beginner'}],
            selectedSkillLevel: '',
            // Hobbies
            inputHobby: '',
            savedHobbies: [],
            optionsHobbies: [],

            error_business_email: false,
            error_message: '',
            success_message: '',
            errorsBe: [],
            initialData: ''
        }
    },
    beforeDestroy() {
        this.$root.$off("user_form_change_step");
        this.$root.$off("addDropdownUserCreate");
    },
    async mounted() {
        await this.getFilters();
        await this.populateUserData();

        this.$root.$on('user_form_change_step', (modeSave, changeData) => {
            if(modeSave == 'save') {
                this.saveAction(changeData);
            } else {
                if(!changeData.step) {
                    this.$emit(changeData.direction);
                } else {
                    this.$emit('goToStep', changeData.step)
                }
            }
        });

        this.$root.$on('addDropdownUserCreate', (data, type) => {
            switch (type) {
                case 'skill':
                    this.optionsSkills.push(data.department)
                    var skillObj = {
                        level: data.level.key,
                        id: data.department.id,
                        name: data.department.name
                    }

                    this.savedSkills.push(skillObj)
                    this.optionsSkills.find(el => el.id == data.department.id).$isDisabled = true
                    break;
            }
        });
    },
    validations: {
        selectedSkillLevel: { required: requiredIf(function (model) {
            return this.selectedSkill != ''
        }) }
    },
    methods: {
        populateUserData() {
            this.emergency_phone = this.user.emergency_phone ? this.user.emergency_phone : '';
            this.address = this.user.address ? this.user.address : '';
            this.business_email = this.user.business_email ? this.user.business_email : '';
            if(this.user.date_of_birth){
                this.date_of_birth = {
                    selectedDate: this.user.date_of_birth,
                    multipleDateRange: [],
                    selectedDates: [],
                    selectedDateTime: false,
                    selectedDatesItem: "",
                    selectedHour: "00",
                    selectedMinute: "00",
                    dateRange: {end: '',start: ''}
                }
            }
            this.married = this.user.married;
            this.has_children = this.user.has_children;
            this.selectedBloodType = this.user.blood_type ? this.user.blood_type : '';
            this.selectedTimezone = this.user.timezone ? this.optionsTimezones.find(el => el.value == this.user.timezone) : '';

            this.social_links = {
                facebook_link: this.user.facebook_link ? this.user.facebook_link : '',
                linkedin_link: this.user.linkedin_link ? this.user.linkedin_link : '',
                instagram_link: this.user.instagram_link ? this.user.instagram_link : '',
                twitter_link: this.user.twitter_link ? this.user.twitter_link : '',
                tiktok_link: this.user.tiktok_link ? this.user.tiktok_link : '',
                youtube_link: this.user.youtube_link ? this.user.youtube_link : ''
            }

            this.savedSkills = this.user.skills;
            this.savedSkills.map((skill) => {
                this.optionsSkills.find(el => el.id == skill.id).$isDisabled = true
            })

            if(this.user.hobbies && this.user.hobbies.length){
                this.user.hobbies.map((hobby) => {
                    this.savedHobbies.push(hobby)
                })
            }
            if(Object.keys(this.user.foreign_languages).length){
                Object.keys(this.user.foreign_languages).map(langId => {
                    var language = this.optionsLanguages.find(el => el.id == langId)
                    language.$isDisabled = true
                    this.savedLanguages.push({
                        id: language.id,
                        name: language.name,
                        levels: this.user.foreign_languages[langId]
                    })
                })						
            }

            this.$nextTick(() => {
                this.loaded = true;

                this.initialData = JSON.stringify({
                    emergency_phone: this.emergency_phone,
                    address: this.address,
                    business_email: this.business_email,
                    married: this.married,
                    has_children: this.has_children,
                    selectedBloodType: this.selectedBloodType,
                    social_links: this.social_links,
                    selectedTimezone: this.selectedTimezone,
                    date_of_birth: Object.keys(this.date_of_birth).length && this.date_of_birth.selectedDate ? this.date_of_birth.selectedDate : '',
                    skills: this.user.skills,
                    hobbies: this.user.hobbies ? this.user.hobbies : [],
                    languages: this.user.foreign_languages
                });
            });
        },
        async getFilters(){
            var filtersDB = {
                timezones: true,
                foreign_languages: true, 
                skills: true, 
                hobbies: true,
            }

            await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: filtersDB })
            .then(({data}) => {
                this.optionsLanguages = data.data.foreign_languages;
                this.optionsSkills = data.data.skills;
                this.optionsHobbies = data.data.hobbies;

                if(data.data.timezones){
                    for (var key of Object.keys(data.data.timezones)){
                        this.optionsTimezones.push({value: key, name: data.data.timezones[key]})
                    }
                }
            })
            .catch(error => {
                if(error.response) {
                    if(error.response.status == 500) {
                        alert(this.$t('error.500'))
                    }
                }
            })
            .finally(() => {
                if(!this.$route.params.slug){
                    setTimeout(() => {
                        this.loaded = true
                        setTimeout(() => {
                            $('.opacity-page').addClass('show')
                        }, 0)
                    }, 0)
                }
            })
        },
        showModal(type, data = false){
            this.$root.$emit('open_modal', type, data);
        },
        changeStepFunction(direction, stepNo = false) {
            if(this.selectedSkill != '' && this.selectedSkillLevel != ''){
                var skillObj = {
                    level: this.selectedSkillLevel ? this.selectedSkillLevel.key : 'undefined',
                    id: this.selectedSkill.id,
                    name: this.selectedSkill.name
                }
                this.savedSkills.push(skillObj);

                // Reset to not duplicate the object in the store function
                this.selectedSkill = '';
                this.selectedSkillLevel = '';
            }
            var selectedSkills = [...this.savedSkills];
            
            var languages = [...this.savedLanguages]
            if(this.selectedLanguage != ''){
                languages.push({
                    id: this.selectedLanguage.id,
                    name: this.selectedLanguage.name,
                    levels: [
                        {type: 'listening', level: this.selectedLevelListening ? this.selectedLevelListening.value : 'undefined'},
                        {type: 'reading', level: this.selectedLevelReading ? this.selectedLevelReading.value : 'undefined'},
                        {type: 'spoke_interaction', level: this.selectedLevelSpInt ? this.selectedLevelSpInt.value : 'undefined'},
                        {type: 'spoke_production', level: this.selectedLevelSpPro ? this.selectedLevelSpPro.value : 'undefined'},
                        {type: 'writing', level: this.selectedLevelWriting ? this.selectedLevelWriting.value : 'undefined'}
                    ]
                })
            }

            var hobbies = [...this.savedHobbies] 
            if(this.savedHobbies.indexOf(this.inputHobby) === -1 && this.inputHobby != '') {
                hobbies.push(this.inputHobby);
            }

            // Check diference between initial data and current
            var currentObject = JSON.stringify({
                emergency_phone: this.emergency_phone,
                address: this.address,
                business_email: this.business_email,
                married: this.married,
                has_children: this.has_children,
                selectedBloodType: this.selectedBloodType,
                social_links: this.social_links,
                selectedTimezone: this.selectedTimezone,
                date_of_birth: Object.keys(this.date_of_birth).length && this.date_of_birth.selectedDate ? this.date_of_birth.selectedDate : '',

                skills: selectedSkills,
                hobbies: hobbies,
                languages: languages
            });

            if(this.initialData === currentObject || !this.loaded) { 
                if(direction) { 
                    this.$emit(direction);
                } else if(stepNo) {
                    this.$emit('goToStep', stepNo)
                }
            } else {
                this.$root.$emit('open_modal', 'confirm_change_step', { direction: direction, step: stepNo });
            }
        },
        saveAction(changeStep = false, withRedirect = false) {
            var btnSubmit = document.getElementById('buttonSubmit');
            btnSubmit.disabled = true;
            this.loadedSubmit = true;

            this.error_message = '';
			this.error_business_email = false;

            this.$v.$touch()
            if(!this.$v.$invalid){

                let objData = {};

                // Skills
                if(this.selectedSkill != ''){
                    var skillObj = {
                        level: this.selectedSkillLevel ? this.selectedSkillLevel.key : 'undefined',
                        id: this.selectedSkill.id,
                        name: this.selectedSkill.name
                    }
                    this.savedSkills.push(skillObj);
                }
                var selectedSkills = [...this.savedSkills];
                if(selectedSkills.length) { objData.skills = selectedSkills }

                // Languages
                var languages = [...this.savedLanguages]
                if(this.selectedLanguage != ''){
                    languages.push({
                        id: this.selectedLanguage.id,
                        name: this.selectedLanguage.name,
                        levels: [
                            {type: 'listening', level: this.selectedLevelListening ? this.selectedLevelListening.value : 'undefined'},
                            {type: 'reading', level: this.selectedLevelReading ? this.selectedLevelReading.value : 'undefined'},
                            {type: 'spoke_interaction', level: this.selectedLevelSpInt ? this.selectedLevelSpInt.value : 'undefined'},
                            {type: 'spoke_production', level: this.selectedLevelSpPro ? this.selectedLevelSpPro.value : 'undefined'},
                            {type: 'writing', level: this.selectedLevelWriting ? this.selectedLevelWriting.value : 'undefined'}
                        ]
                    })
                }
                if(languages.length) { objData.foreign_languages = languages }

                // Hobbies
                var hobbies = [...this.savedHobbies] 
                if(this.savedHobbies.indexOf(this.inputHobby) === -1 && this.inputHobby != '') {
                    hobbies.push(this.inputHobby);
                }
                if(hobbies.length) { objData.hobbies = hobbies }
                
                objData.emergency_phone = this.emergency_phone;
                objData.address = this.address;
                objData.business_email = this.business_email;
                objData.date_of_birth = Object.keys(this.date_of_birth).length ? this.date_of_birth.selectedDate : '';
                objData.married = this.married !== '' ? this.married : '';
                objData.has_children = this.has_children !== '' ? this.has_children : '';
                objData.blood_type = this.selectedBloodType;
                objData.timezone = this.selectedTimezone ? this.selectedTimezone.value : ''

                objData.facebook_link = this.social_links.facebook_link;
                objData.linkedin_link = this.social_links.linkedin_link;
                objData.instagram_link = this.social_links.instagram_link;
                objData.twitter_link = this.social_links.twitter_link;
                objData.tiktok_link = this.social_links.tiktok_link;
                objData.youtube_link = this.social_links.youtube_link;

                this.updateUser(objData, changeStep, withRedirect)
            } else {
                this.loadedSubmit = false;
                btnSubmit.disabled = false;
            }
        },
        updateUser(objData, changeStep, withRedirect){
            var btnSubmit = document.getElementById('buttonSubmit');

            axios.patch(`/users/${this.user.slug}`, objData)
            .then(({data}) => {
                this.$parent.userData = data.data;
                this.loadedSubmit = false;
                btnSubmit.disabled = false;

                this.success_message = this.$t('create-user.success_update_msg');
                setTimeout(() => {
                    this.success_message = '';
                }, 3000);

                if(changeStep) {
                    this.$nextTick(() => {
                        if(!changeStep.step) {
                            this.$emit(changeStep.direction);
                        } else {
                            this.$emit('goToStep', changeStep.step)
                        }
                    });
                }

                if(withRedirect) {
                    // if(this.selectedVacantJob) {
                        // this.showModal('vacant_job_duration', { vacantJob: this.selectedVacantJob, userSlug: data.data.slug } )
                    // } else {
                        if(this.$route.query.department_id){
                            this.$router.push({name: 'company'})
                        }else{
                            this.$router.push({name: 'user-show', params: { slug: data.data.slug }})
                        }
                    // }
                }
            })
            .catch(error => {
                this.errorsBe = error.response.data.errors;
                this.loadedSubmit = false;
                btnSubmit.disabled = false;

                setTimeout(()=>{
                    if(error.response.status == 500){
                        this.error_message = this.$t('error.500')
                    } else if(error.response.data.errors.business_email[0]) {
                        this.error_message = error.response.data.errors.business_email[0]
                        this.error_business_email = true;
                    }
                }, 300)
            })
            .finally(() => {
                this.initialData = JSON.stringify({
                    emergency_phone: this.emergency_phone,
                    address: this.address,
                    business_email: this.business_email,
                    married: this.married,
                    has_children: this.has_children,
                    selectedBloodType: this.selectedBloodType,
                    social_links: this.social_links,
                    selectedTimezone: this.selectedTimezone,
                    date_of_birth: Object.keys(this.date_of_birth).length && this.date_of_birth.selectedDate ? this.date_of_birth.selectedDate : '',
                    skills: this.user.skills,
                    hobbies: this.user.hobbies,
                    languages: this.user.foreign_languages
                });
            })
        },

        // START Languages
        addOptionLanguage(){
            var languageObj = {
                id: this.selectedLanguage.id,
                name: this.selectedLanguage.name,
                levels: [
                    {type: 'listening', level: this.selectedLevelListening ? this.selectedLevelListening.value : 'undefined'},
                    {type: 'reading', level: this.selectedLevelReading ? this.selectedLevelReading.value : 'undefined'},
                    {type: 'spoke_interaction', level: this.selectedLevelSpInt ? this.selectedLevelSpInt.value : 'undefined'},
                    {type: 'spoke_production', level: this.selectedLevelSpPro ? this.selectedLevelSpPro.value : 'undefined'},
                    {type: 'writing', level: this.selectedLevelWriting ? this.selectedLevelWriting.value : 'undefined'}
                ]
            }

            this.savedLanguages.push(languageObj)

            this.optionsLanguages.find(el => el.id == this.selectedLanguage.id).$isDisabled = true

            this.selectedLanguage = ''
            this.clearLanguagesLevels()
        },
        clearLanguagesLevels() {
            this.selectedLevelListening = ''
            this.selectedLevelReading = ''
            this.selectedLevelSpInt = ''
            this.selectedLevelSpPro = ''
            this.selectedLevelWriting = ''
        },
        editLanguageLevel(obj){
            var language_selected = this.optionsLanguages.find(el => el.id == obj.id)
            language_selected.$isDisabled = false
            this.savedLanguages.splice(this.savedLanguages.findIndex((el) => el.id == obj.id), 1)

            this.selectedLanguage = language_selected

            var listening = obj.levels.find((el) => el.type == 'listening')
            this.selectedLevelListening = listening.level != 'undefined' ? this.optionsListening.find(el => el.value == listening.level) : ''
            
            var reading = obj.levels.find((el) => el.type == 'reading')
            this.selectedLevelReading = reading.level != 'undefined' ? this.optionsReading.find(el => el.value == reading.level) : ''
            
            var spoke_interaction = obj.levels.find((el) => el.type == 'spoke_interaction')
            this.selectedLevelSpInt = spoke_interaction.level != 'undefined' ? this.optionsSpokenInteraction.find(el => el.value == spoke_interaction.level) : ''
            
            var spoke_production = obj.levels.find((el) => el.type == 'spoke_production')
            this.selectedLevelSpPro = spoke_production.level != 'undefined' ? this.optionsSpokenProduction.find(el => el.value == spoke_production.level) : ''
            
            var writing = obj.levels.find((el) => el.type == 'writing')
            this.selectedLevelWriting = writing.level != 'undefined' ? this.optionsWriting.find(el => el.value == writing.level) : ''
        },
        deleteLanguageLevel(language){
            this.optionsLanguages.find(el => el.id == language.id).$isDisabled = false
            this.savedLanguages.splice(this.savedLanguages.findIndex((el) => el.id == language.id), 1)
        },
        // END Languages

        // START Skills
        addSkill(){
            this.$v.selectedSkillLevel.$touch()
            if (!this.$v.selectedSkillLevel.$invalid) {
                var skillObj = {
                    level: this.selectedSkillLevel ? this.selectedSkillLevel.key : 'undefined',
                    id: this.selectedSkill.id,
                    name: this.selectedSkill.name
                }

                this.savedSkills.push(skillObj)

                this.optionsSkills.find(el => el.id == this.selectedSkill.id).$isDisabled = true

                this.selectedSkill = ''
                this.selectedSkillLevel = ''
                this.$v.selectedSkillLevel.$reset()
            }
        },
        editSkillLevel(obj){
            var skill_selected = this.optionsSkills.find(el => el.id == obj.id)
            skill_selected.$isDisabled = false
            this.savedSkills.splice(this.savedSkills.findIndex((el) => el.id == obj.id), 1)

            this.selectedSkill = skill_selected
            this.selectedSkillLevel = obj.level != 'undefined' ? this.optionsSkillsLevels.find(el => el.key == obj.level) : ''
        },
        deleteSkillLevel(skill){
            if(this.optionsSkills.find(el => el.id == skill.id)){
                this.optionsSkills.find(el => el.id == skill.id).$isDisabled = false
            }
            this.savedSkills.splice(this.savedSkills.findIndex((el) => el.skill == skill), 1)
        },
        // END Skills

        // START Hobbies
        addNewHobby(newHobby){
            this.optionsHobbies.push(newHobby)
            this.inputHobby = newHobby
        },
        addHobby(){
            if(this.savedHobbies.indexOf(this.inputHobby) === -1 && this.inputHobby != '') {
                this.savedHobbies.push(this.inputHobby);
            }
            this.inputHobby = ''
        },
        deleteHobby(index){
            this.savedHobbies.splice(index,1);
        },
        // END Hobbies
    }
}
</script>